import React, { useState } from 'react';
import styled from 'styled-components';

import Layout from '../Layout';
import Narrow from '../Narrow';
import PageTitle from '../PageTitle';
import SmallText from '../SmallText';
import Spacer from '../Spacer';
import Subheading from '../Subheading';
import Textblob from '../Textblob';
import VideoWithViewport from '../VideoWithViewport';

const Description = styled(SmallText)`
  margin: 1rem 0;
`;

export default ({
  pageContext: {
    searchIndex,
    reels: {
      acf: { textblob, reels },
    },
  },
}) => {
  return (
      <>
          <Layout background={true} searchIndex={searchIndex}>
              <PageTitle>Reels</PageTitle>
              <Spacer space="3rem" />
              <Narrow>
                  <Textblob>{textblob}</Textblob>
              </Narrow>
              {reels.map((reel, i) => (
                  <React.Fragment key={i}>
                      <Subheading>{reel.subheadline}</Subheading>
                      <VideoWithViewport
                          hd={reel.reel_video.hd}
                          sd={reel.reel_video.sd}
                          poster={
                              reel.reel_video.poster_image
                                  ? reel.reel_video.poster_image.url
                                  : ""
                          }
                          overlayBackground={
                              reel.reel_video.poster_image
                                  ? reel.reel_video.poster_image.url
                                  : ""
                          }
                          overlay={reel.reel_video.fade_over_black}
                          controls={true}
                      />
                      <Description
                          dangerouslySetInnerHTML={{ __html: reel.description }}
                      />
                  </React.Fragment>
              ))}
          </Layout>
      </>
  );
};
